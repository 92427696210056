<template>
  <div id="ranking" class="ranking" :class="{ 'night-mode': isNight }">
    <Top ref="top"></Top>
    <div class="ranking-banner">
      <div class="banner-title">
        <span>排行榜</span>
      </div>
    </div>

    <div class="ranking-container">
      <!-- 榜单导航选项卡 -->
      <div class="ranking-tabs">
        <div
          class="ranking-tab"
          :class="{ active: currentView === 'all' }"
          @click="changeView('all')"
        >
          全部榜
        </div>
        <div
          class="ranking-tab"
          :class="{ active: currentView === 'movie' }"
          @click="changeView('movie')"
        >
          电影榜
        </div>
        <div
          class="ranking-tab"
          :class="{ active: currentView === 'tv' }"
          @click="changeView('tv')"
        >
          热剧榜
        </div>
        <div
          class="ranking-tab"
          :class="{ active: currentView === 'variety' }"
          @click="changeView('variety')"
        >
          综艺榜
        </div>
        <div
          class="ranking-tab"
          :class="{ active: currentView === 'anime' }"
          @click="changeView('anime')"
        >
          动漫榜
        </div>
      </div>

      <!-- 全部榜单视图 -->
      <div class="all-rankings" v-if="currentView === 'all'">
        <div class="ranking-row">
          <!-- 电影榜 -->
          <div class="ranking-box">
            <div class="box-header">
              <h2>电影榜</h2>
              <div class="time-tabs">
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[0] === 'week' }"
                  @click="changeAllRankType(0, 'week')"
                >
                  周
                </div>
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[0] === 'month' }"
                  @click="changeAllRankType(0, 'month')"
                >
                  月
                </div>
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[0] === 'total' }"
                  @click="changeAllRankType(0, 'total')"
                >
                  年
                </div>
              </div>
            </div>
            <div class="box-content">
              <div class="loading-spinner" v-if="loadingStates[0]"></div>
              <div class="rank-list" v-else>
                <div
                  class="rank-item"
                  v-for="(item, index) in allRankingLists[0].slice(0, 10)"
                  :key="index"
                >
                  <div class="rank-number" :class="{ 'top-three': index < 3 }">{{ index + 1 }}</div>
                  <div class="rank-poster">
                    <a
                      v-if="item.pic"
                      :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                      target="_blank"
                    >
                      <img :src="item.pic" :alt="item.title" />
                    </a>
                    <div class="poster-placeholder" v-else></div>
                  </div>
                  <div class="rank-info">
                    <div>
                      <a
                        :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                        target="_blank"
                        class="rank-title"
                      >
                        {{ item.title }}
                      </a>
                      <div class="rank-meta">
                        <span>{{ item.year }}</span>
                        <span>{{ item.region }}</span>
                        <span>{{ item.language }}</span>
                      </div>
                    </div>
                    <div class="rank-bottom">
                      <div class="rank-hits">{{ item.hits }}</div>
                      <div class="rank-score">{{ item.score?.toFixed(1) || '0.0' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 电视剧榜 -->
          <div class="ranking-box">
            <div class="box-header">
              <h2>热剧榜</h2>
              <div class="time-tabs">
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[1] === 'week' }"
                  @click="changeAllRankType(1, 'week')"
                >
                  周
                </div>
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[1] === 'month' }"
                  @click="changeAllRankType(1, 'month')"
                >
                  月
                </div>
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[1] === 'total' }"
                  @click="changeAllRankType(1, 'total')"
                >
                  年
                </div>
              </div>
            </div>
            <div class="box-content">
              <div class="loading-spinner" v-if="loadingStates[1]"></div>
              <div class="rank-list" v-else>
                <div
                  class="rank-item"
                  v-for="(item, index) in allRankingLists[1].slice(0, 10)"
                  :key="index"
                >
                  <div class="rank-number" :class="{ 'top-three': index < 3 }">{{ index + 1 }}</div>
                  <div class="rank-poster">
                    <a
                      v-if="item.pic"
                      :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                      target="_blank"
                    >
                      <img :src="item.pic" :alt="item.title" />
                    </a>
                    <div class="poster-placeholder" v-else></div>
                  </div>
                  <div class="rank-info">
                    <div>
                      <a
                        :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                        target="_blank"
                        class="rank-title"
                      >
                        {{ item.title }}
                      </a>
                      <div class="rank-meta">
                        <span>{{ item.year }}</span>
                        <span>{{ item.region }}</span>
                        <span>{{ item.language }}</span>
                      </div>
                    </div>
                    <div class="rank-bottom">
                      <div class="rank-hits">{{ item.hits }}</div>
                      <div class="rank-score">{{ item.score?.toFixed(1) || '0.0' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 综艺榜 -->
          <div class="ranking-box">
            <div class="box-header">
              <h2>综艺榜</h2>
              <div class="time-tabs">
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[2] === 'week' }"
                  @click="changeAllRankType(2, 'week')"
                >
                  周
                </div>
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[2] === 'month' }"
                  @click="changeAllRankType(2, 'month')"
                >
                  月
                </div>
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[2] === 'total' }"
                  @click="changeAllRankType(2, 'total')"
                >
                  年
                </div>
              </div>
            </div>
            <div class="box-content">
              <div class="loading-spinner" v-if="loadingStates[2]"></div>
              <div class="rank-list" v-else>
                <div
                  class="rank-item"
                  v-for="(item, index) in allRankingLists[2].slice(0, 10)"
                  :key="index"
                >
                  <div class="rank-number" :class="{ 'top-three': index < 3 }">{{ index + 1 }}</div>
                  <div class="rank-poster">
                    <a
                      v-if="item.pic"
                      :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                      target="_blank"
                    >
                      <img :src="item.pic" :alt="item.title" />
                    </a>
                    <div class="poster-placeholder" v-else></div>
                  </div>
                  <div class="rank-info">
                    <div>
                      <a
                        :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                        target="_blank"
                        class="rank-title"
                      >
                        {{ item.title }}
                      </a>
                      <div class="rank-meta">
                        <span>{{ item.year }}</span>
                        <span>{{ item.region }}</span>
                        <span>{{ item.language }}</span>
                      </div>
                    </div>
                    <div class="rank-bottom">
                      <div class="rank-hits">{{ item.hits }}</div>
                      <div class="rank-score">{{ item.score?.toFixed(1) || '0.0' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 动漫榜 -->
          <div class="ranking-box">
            <div class="box-header">
              <h2>动漫榜</h2>
              <div class="time-tabs">
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[3] === 'week' }"
                  @click="changeAllRankType(3, 'week')"
                >
                  周
                </div>
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[3] === 'month' }"
                  @click="changeAllRankType(3, 'month')"
                >
                  月
                </div>
                <div
                  class="time-tab"
                  :class="{ active: allRankTypes[3] === 'total' }"
                  @click="changeAllRankType(3, 'total')"
                >
                  年
                </div>
              </div>
            </div>

            <div class="box-content">
              <div class="loading-spinner" v-if="loadingStates[3]"></div>
              <div class="rank-list" v-else>
                <div
                  class="rank-item"
                  v-for="(item, index) in allRankingLists[3].slice(0, 10)"
                  :key="index"
                >
                  <div class="rank-number" :class="{ 'top-three': index < 3 }">{{ index + 1 }}</div>
                  <div class="rank-poster">
                    <a
                      v-if="item.pic"
                      :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                      target="_blank"
                    >
                      <img :src="item.pic" :alt="item.title" />
                    </a>
                    <div class="poster-placeholder" v-else></div>
                  </div>
                  <div class="rank-info">
                    <div>
                      <a
                        :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                        target="_blank"
                        class="rank-title"
                      >
                        {{ item.title }}
                      </a>
                      <div class="rank-meta">
                        <span>{{ item.year }}</span>
                        <span>{{ item.language }}</span>
                        <span>{{ item.region }}</span>
                      </div>
                    </div>
                    <div class="rank-bottom">
                      <div class="rank-hits">{{ item.hits }}</div>
                      <div class="rank-score">{{ item.score?.toFixed(1) || '0.0' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 单个分类榜单详细视图 -->
      <div class="single-ranking" v-if="currentView !== 'all'">
        <div class="single-ranking-header">
          <div class="time-tabs">
            <div
              class="time-tab"
              :class="{ active: rankType === 'week' }"
              @click="changeRankType('week')"
            >
              周榜
            </div>
            <div
              class="time-tab"
              :class="{ active: rankType === 'month' }"
              @click="changeRankType('month')"
            >
              月榜
            </div>
            <div
              class="time-tab"
              :class="{ active: rankType === 'total' }"
              @click="changeRankType('total')"
            >
              年榜
            </div>
          </div>
        </div>

        <div class="single-ranking-content">
          <div class="loading-spinner center-loader" v-if="loading"></div>
          <div class="detailed-rank-list" v-else-if="rankingLists[0] && rankingLists[0].length > 0">
            <div class="detailed-rank-item" v-for="(item, index) in rankingLists[0]" :key="index">
              <div class="detailed-rank-number" :class="{ 'top-three': index < 3 }">
                {{ index + 1 }}
              </div>
              <div class="detailed-rank-poster">
                <a
                  v-if="item.pic"
                  :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                  target="_blank"
                >
                  <img :src="item.pic" :alt="item.title" />
                </a>
                <div class="poster-placeholder" v-else></div>
              </div>
              <div class="detailed-rank-info">
                <div class="detailed-rank-title-row">
                  <a
                    :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                    target="_blank"
                    class="detailed-rank-title"
                  >
                    {{ item.title }}
                  </a>
                </div>
                <div class="detailed-rank-meta">
                  <span v-if="item.year">{{ item.year }}</span>
                  <span v-if="$store.state.menudist[item.category_id]">
                    {{ $store.state.menudist[item.category_id] }}
                  </span>
                  <span v-if="item.region">{{ item.region }}</span>
                  <span v-if="item.language">{{ item.language }}</span>
                </div>

                <div class="detailed-rank-details">
                  <div class="detail-item" v-if="item.state">
                    <span class="detail-label">{{ item.state }}：</span>
                    <span class="detail-value">{{ item.last_fragment_symbol }}</span>
                  </div>
                  <div class="detail-item" v-if="item.director">
                    <span class="detail-label">导演：</span>
                    <span class="detail-value" :title="item.director">{{ item.director }}</span>
                  </div>
                  <div class="detail-item" v-if="item.starring">
                    <span class="detail-label">主演：</span>
                    <span class="detail-value" :title="item.starring">{{ item.starring }}</span>
                  </div>
                  <div class="detail-item" v-if="item.description">
                    <span class="detail-label">简介：</span>
                    <span class="detail-value" :title="item.description">
                      {{ item.description }}
                    </span>
                  </div>
                </div>
                <div class="detailed-rank-stats">
                  <div class="film_list">
                    <div class="film_icon f_i-1"></div>
                    <span>{{ item.comment || 0 }}</span>
                  </div>
                  <div class="film_list">
                    <div
                      class="film_icon"
                      :class="item.evaluate_type > 0 ? 'f_i-2-act' : 'f_i-2'"
                    ></div>
                    <span>{{ item.appreciate || 0 }}</span>
                  </div>
                  <div class="film_list">
                    <div
                      class="film_icon"
                      :class="item.evaluate_type < 0 ? 'f_i-3-act' : 'f_i-3'"
                    ></div>
                    <span>{{ item.depreciate || 0 }}</span>
                  </div>
                  <div class="film_list">
                    <div class="film_icon" :class="item.in_favorite ? 'f_i-4-act' : 'f_i-4'"></div>
                    <span>{{ item.favorite || 0 }}</span>
                  </div>
                  <div class="detailed-rank-score">{{ item.score?.toFixed(1) || '0.0' }}</div>
                </div>
                <div class="detailed-rank-play" v-if="item.video_fragment_list">
                  <template v-if="item.multiple_fragment && item.video_fragment_list.length > 8">
                    <a
                      v-for="(fragment, fIndex) in item.video_fragment_list.slice(0, 3)"
                      :key="fIndex"
                      class="play-item"
                      :href="
                        YS.returnURL(
                          { path: '/play', query: { video_id: item.id, lid: fragment.id } },
                          true,
                        )
                      "
                      target="_blank"
                    >
                      {{ fragment.symbol }}
                    </a>
                    <a
                      class="play-item more"
                      :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                      target="_blank"
                    >
                      ...
                    </a>
                    <a
                      v-for="(fragment, fIndex) in item.video_fragment_list.slice(-4)"
                      :key="fIndex"
                      class="play-item"
                      :href="
                        YS.returnURL(
                          { path: '/play', query: { video_id: item.id, lid: fragment.id } },
                          true,
                        )
                      "
                      target="_blank"
                    >
                      {{ fragment.symbol }}
                    </a>
                  </template>
                  <template
                    v-else-if="item.multiple_fragment && item.video_fragment_list.length <= 8"
                  >
                    <a
                      v-for="(fragment, fIndex) in item.video_fragment_list"
                      :key="fIndex"
                      class="play-item"
                      :href="
                        YS.returnURL(
                          { path: '/play', query: { video_id: item.id, lid: fragment.id } },
                          true,
                        )
                      "
                      target="_blank"
                    >
                      {{ fragment.symbol }}
                    </a>
                  </template>
                  <template v-else>
                    <a
                      class="play-item play-now"
                      :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                      target="_blank"
                    >
                      立即播放
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-data" v-else>
            <div class="loading-spinner"></div>
          </div>
        </div>
      </div>
    </div>
    <Bottom v-if="!loading"></Bottom>
  </div>
</template>

<script>
let that;
import Top from '../components/Top';
import Bottom from '../components/Bottom';

export default {
  name: 'Ranking',
  components: {
    Top,
    Bottom,
  },
  data() {
    return {
      isNight: window.g.isNight,
      currentView: 'all', // 'all', 'movie', 'tv', 'variety', 'anime'
      viewToCategory: {
        movie: 0,
        tv: 1,
        variety: 2,
        anime: 3,
      },
      categories: [
        { name: '电影', id: null },
        { name: '电视剧', id: null },
        { name: '综艺', id: null },
        { name: '动漫', id: null },
      ],
      rankType: 'week', // 'week', 'month', 'total'
      allRankTypes: ['week', 'week', 'week', 'week'], // 每个分类的榜单类型
      loading: false,
      loadingStates: [false, false, false, false], // 每个分类的加载状态
      rankingLists: [[]],
      allRankingLists: [[], [], [], []], // 所有榜单的数据
      weekTime: 0,
      monthTime: 0,
      yearTime: 0, // 添加年榜单时间戳
      menutotal: [],
    };
  },
  created() {
    that = this;
    let time = Math.round(new Date().getTime() / 1000).toString();
    that.weekTime = time - 7 * 24 * 60 * 60;
    that.monthTime = time - 30 * 24 * 60 * 60;
    that.yearTime = time - 365 * 24 * 60 * 60; // 计算一年的时间差
  },
  async mounted() {
    // 从本地存储获取菜单分类信息
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore && menutotalstore.time > new Date().getTime()) {
      that.menutotal = menutotalstore.data;
      that.initCategoryIds();
    } else {
      await that.getCategories();
    }

    // 初始化加载所有分类的排行榜
    that.loadAllRankings();

    // 检查URL参数，决定默认显示的视图
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    if (view && ['all', 'movie', 'tv', 'variety', 'anime'].includes(view)) {
      that.changeView(view);
    }
  },
  methods: {
    // 获取分类数据
    async getCategories() {
      try {
        let data = await that.api.user.api_categoryhome({});
        that.menutotal = [];

        if (window.g.isNight) {
          const nightData = data.category_list.find((item) => item.symbol === 'night');
          data.category_list &&
            data.category_list.forEach((e) => {
              that.$store.commit('changeEvery', { key: 'menudist', value: e.name, key2: e.id });
              if (e.pid == nightData.id) {
                that.menutotal.push(e);
              }
            });
          localStorage.setItem('nightData', JSON.stringify(nightData));
        } else {
          data.category_list &&
            data.category_list.forEach((e) => {
              that.$store.commit('changeEvery', { key: 'menudist', value: e.name, key2: e.id });
              if (e.pid == 0) {
                e.list = [];
                that.menutotal.push(e);
              } else {
                that.menutotal.forEach((a, i) => {
                  if (a.id == e.pid) {
                    that.menutotal[i].list.push(e);
                  }
                });
              }
            });
        }

        // 存储至本地
        that.YS.savestore('menutotal', that.menutotal, 600);

        // 初始化各分类ID
        that.initCategoryIds();
      } catch (error) {
        console.log(error);
      }
    },

    // 初始化四个分类的ID
    initCategoryIds() {
      // 根据名称找到对应的分类ID
      that.menutotal.forEach((category) => {
        if (category.name === '电影') {
          that.categories[0].id = category.id;
        } else if (category.name === '电视剧') {
          that.categories[1].id = category.id;
        } else if (category.name === '综艺') {
          that.categories[2].id = category.id;
        } else if (category.name === '动漫') {
          that.categories[3].id = category.id;
        }
      });
    },

    // 切换视图
    changeView(view) {
      that.currentView = view;

      if (view === 'all') {
        that.loadAllRankings();
      } else {
        const categoryIndex = that.viewToCategory[view];
        if (categoryIndex !== undefined) {
          that.getCategoryRanking(categoryIndex);
        }
      }

      // 更新URL参数，不刷新页面
      const url = new URL(window.location);
      url.searchParams.set('view', view);
      window.history.pushState({}, '', url);
    },

    // 切换排行榜类型（用于单个分类详细视图）
    changeRankType(type) {
      that.rankType = type;

      const categoryIndex = that.viewToCategory[that.currentView];
      if (categoryIndex !== undefined) {
        that.getCategoryRanking(categoryIndex);
      }
    },

    // 切换所有榜单中某个分类的榜单类型
    changeAllRankType(categoryIndex, type) {
      that.allRankTypes[categoryIndex] = type;
      that.loadSingleCategoryRanking(categoryIndex);
    },

    // 加载所有分类的排行榜数据
    loadAllRankings() {
      that.categories.forEach((category, index) => {
        if (category.id) {
          that.loadSingleCategoryRanking(index);
        }
      });
    },

    // 加载单个分类的排行榜数据（用于所有榜单视图）
    loadSingleCategoryRanking(index) {
      const categoryId = that.categories[index].id;
      if (!categoryId) return;

      that.$set(that.loadingStates, index, true);

      let time = null;
      if (that.allRankTypes[index] === 'week') {
        time = that.weekTime;
      } else if (that.allRankTypes[index] === 'month') {
        time = that.monthTime;
      } else if (that.allRankTypes[index] === 'total') {
        time = that.yearTime; // 使用年榜单时间戳
      }

      const params = window.g.isNight
        ? { category_id: categoryId, start_time: time }
        : { parent_category_id: categoryId, start_time: time };

      that.api.user
        .api_videorank(params)
        .then((data) => {
          if (data.video_rank_list) {
            that.$set(that.allRankingLists, index, data.video_rank_list);
          } else {
            that.$set(that.allRankingLists, index, []);
          }
        })
        .finally(() => {
          that.$set(that.loadingStates, index, false);
        });
    },

    // 获取单个分类榜单详细数据
    getCategoryRanking(categoryIndex) {
      const categoryId = that.categories[categoryIndex].id;
      if (!categoryId) return;

      that.loading = true;
      that.rankingLists = [[]];

      let time = null;
      if (that.rankType === 'week') {
        time = that.weekTime;
      } else if (that.rankType === 'month') {
        time = that.monthTime;
      } else if (that.rankType === 'total') {
        time = that.yearTime;
      }

      const params = window.g.isNight
        ? { category_id: categoryId, start_time: time, pagesize: 30 }
        : { parent_category_id: categoryId, start_time: time, pagesize: 30 };

      that.api.user
        .api_videorank(params)
        .then((data) => {
          if (data.video_rank_list) {
            that.rankingLists = [data.video_rank_list];
          } else {
            that.rankingLists = [[]];
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ranking {
  min-height: 100vh;
  background: #0f0f1e;

  &.night-mode {
    background-color: #181c27;
    color: #e0e0e0;

    .ranking-banner {
      // background: linear-gradient(180deg, rgba(24,28,39,0) 0%, #181c27 100%), url('../assets/img/vip-logo.png');
      background-image: url('../assets/img/getta3.png');
      background-size: cover;
      background-position: center;
    }

    .ranking-container {
      color: #e0e0e0;
    }

    .ranking-tabs {
      background-color: #252b39;
      border-color: #252b39;


      .ranking-tab {
        color: #999;

        &.active {
          color: #149bec;
          border-bottom-color: #149bec;
        }

        &:hover {
          color: #149bec;
        }
      }
    }

    .ranking-box,
    .single-ranking-content {
      background-color: #252b39;
      border-color: #252b39;
    }

    .box-header,
    .single-ranking-header {
      border-bottom-color: #333;
    }

    .rank-item,
    .detailed-rank-item {
      border-bottom-color: #333;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    .rank-title,
    .detailed-rank-title {
      color: #e0e0e0;

      &:hover {
        color: #149bec;
      }
    }

    .rank-meta,
    .detailed-rank-meta {
      color: #999;
    }

    .time-tab {
      color: #999;
    padding: 2px 6px;
    background: #17192a;
      &.active {
        color: #149bec;
        border-bottom-color: #149bec;
      }

      &:hover {
        color: #149bec;
      }
    }
  }
}

.ranking-banner {
  width: 100%;
  height: 280px;
  // background: linear-gradient(180deg, rgba(15,15,30,0) 0%, rgba(15,15,30,0.2) 20%, rgba(15,15,30,0.4) 40%, rgba(15,15,30,0.6) 60%, rgba(15,15,30,0.8) 80%, #0f0f1e 100%), url('../assets/img/vip-logo.png');
  background-image: url('../assets/img/getta3.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  .banner-title {
    font-size: 32px;
    font-weight: bold;
    color: white;
    text-align: center;

    span {
      display: inline-block;
      position: relative;
      padding: 0 20px;

      &:before,
      &:after {
        content: '';
        width: 20px;
        height: 1px;
        background: white;
        position: absolute;
        top: 50%;
      }

      &:before {
        left: -10px;
      }

      &:after {
        right: -10px;
      }
    }
  }
}

.ranking-container {
  width: 100%;
  min-width: 1800px;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 20px 50px;
}

.ranking-tabs {
  display: flex;
  border-bottom: 1px solid #252b39;
  background-color: #252b39;
  margin-bottom: 20px;
  justify-content: center;

  .ranking-tab {
    padding: 15px 30px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    color: #999;
    transition: all 0.3s;

    &.active {
      color: #149bec;
      font-weight: bold;
      border-bottom: 2px solid #149bec;
    }

    &:hover {
      color: #149bec;
    }
  }
}

/* 所有榜单样式 */
.all-rankings {
  .ranking-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  .ranking-box {
    width: 450px;
    background-color: #252b39;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: 1px solid #333;
  }

  .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #333;

    h2 {
      font-size: 18px;
      margin: 0;
      font-weight: bold;
      color: #e0e0e0;
    }

    .time-tabs {
      display: flex;

      .time-tab {
        padding: 2px 10px;
        cursor: pointer;
        font-size: 14px;
        color: #999;

        &.active {
          color: #149bec;
          font-weight: bold;
        }

        &:not(:last-child) {
          border-right: 1px solid #333;
        }
      }
    }
  }

  .box-content {
    min-height: 400px;
    position: relative;

    .loading-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border: 3px solid #333;
      border-top: 3px solid #149bec;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }

  .rank-list {
    .rank-item {
      display: flex;
      align-items: flex-start;
      padding: 12px 15px;
      border-bottom: 1px solid #333;
      transition: background-color 0.3s;
      height: 170px;
      position: relative;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .rank-number {
      width: 30px;
      font-size: 20px;
      font-weight: bold;
      color: #999;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 5px;

      &.top-three {
        color: #ff6b00;
      }
    }

    .rank-poster {
      width: 120px;
      height: 170px;
      margin: 0 15px;
      overflow: hidden;
      border-radius: 4px;
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .poster-placeholder {
        width: 100%;
        height: 100%;
        background-color: #333;
      }
    }

    .rank-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 0;
      padding-right: 15px;
      height: 100%;
    }

    .rank-title {
      display: block;
      font-size: 15px;
      color: #e0e0e0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      margin-bottom: 8px;
      font-weight: 500;

      &:hover {
        color: #149bec;
      }
    }

    .rank-meta {
      font-size: 13px;
      color: #999;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        margin-right: 12px;
        padding: 2px 6px;
        background: #17192a;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .rank-bottom {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .rank-hits {
      font-size: 13px;
      color: #999;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url('../assets/img/fire.webp');
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 4px;
      }
    }

    .rank-score {
      font-size: 22px;
      font-weight: bold;
      color: #ff6b00;
      width: 45px;
      text-align: right;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-shrink: 0;
    }
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* 单个分类详细榜单样式 */
.single-ranking {
  background-color: #252b39;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #333;

  .single-ranking-header {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #333;

    .time-tabs {
      display: flex;

      .time-tab {
        padding: 5px 15px;
        cursor: pointer;
        font-size: 14px;
        color: #999;
        position: relative;

        &.active {
          color: #149bec;
          font-weight: bold;

          &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 15%;
            width: 70%;
            height: 2px;
            background-color: #149bec;
          }
        }
      }
    }
  }

  .single-ranking-content {
    min-height: 500px;
    padding: 20px;
    position: relative;

    .center-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .detailed-rank-list {
    .detailed-rank-item {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #333;

      &:last-child {
        border-bottom: none;
      }
    }

    .detailed-rank-number {
      width: 40px;
      font-size: 20px;
      font-weight: bold;
      color: #999;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      &.top-three {
        color: #ff6b00;
      }
    }

    .detailed-rank-poster {
      width: 150px;
      height: 220px;
      margin-right: 20px;
      overflow: hidden;
      border-radius: 4px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .poster-placeholder {
        width: 100%;
        height: 100%;
        background-color: #333;
      }
    }

    .detailed-rank-info {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .detailed-rank-title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .detailed-rank-title {
      font-size: 18px;
      font-weight: bold;
      color: #e0e0e0;
      text-decoration: none;

      &:hover {
        color: #149bec;
      }
    }

    .detailed-rank-score {
      font-size: 24px;
      font-weight: bold;
      color: #ff6b00;
      margin-left: 32px;
    }

    .detailed-rank-meta {
      display: flex;
      margin-bottom: 10px;
      font-size: 14px;
      color: #999;

      span {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .detailed-rank-stats {
      display: flex;
      gap: 20px;
      margin: 10px 0;
      align-items: stretch;
      justify-content: flex-end;

      .film_list {
        display: flex;
        align-items: center;
        color: #999;
        font-size: 13px;

        .film_icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    .detailed-rank-details {
      margin: 10px 0;

      .detail-item {
        font-size: 13px;
        color: #999;
        margin-bottom: 5px;

        .detail-label {
          color: #666;
          margin-right: 5px;
        }

        .detail-value {
          color: #999;
        }
      }
    }

    .detailed-rank-play {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 10px;

      .play-item {
        padding: 4px 12px;
        background: #333;
        color: #999;
        border-radius: 4px;
        text-decoration: none;
        font-size: 13px;
        transition: all 0.3s;

        &:hover {
          background: #149bec;
          color: #fff;
        }

        &.play-now {
          background: #ff6b00;
          color: #fff;

          &:hover {
            background: #ff8533;
          }
        }

        &.more {
          background: transparent;
          color: #999;

          &:hover {
            background: transparent;
            color: #149bec;
          }
        }
      }
    }
  }

  .empty-data {
    padding: 40px 0;
    text-align: center;
    color: #999;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;

    .loading-spinner {
      width: 40px;
      height: 40px;
      border: 3px solid #333;
      border-top: 3px solid #149bec;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }
}

.dots-6 {
  width: 40px;
  height: 16px;
  background: radial-gradient(circle closest-side, currentColor 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, currentColor 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, currentColor 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 100%;
  background-repeat: no-repeat;
  animation: dots-6 1s infinite linear;
}

@keyframes dots-6 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
</style> 